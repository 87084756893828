import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import Spinner from '../components/Spinner';
import Layout from '../Layout';

export default function Home() {
  const navigate = useNavigate();

  useEffect(() => {
    const timer = setTimeout(() => {
      navigate('/0430vmp'); // Replace with the desired route
    }, 4000); // 10 seconds delay

    return () => clearTimeout(timer);
  }, [navigate]);

  return (
    <Layout>
      <Wrapper>
        <Gap />
        <Spinner />
        <Text>Loading...</Text>
      </Wrapper>
    </Layout>
  );
}

const Gap = styled.div`
  height: 10vh;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Text = styled.h3`
  text-align: center;
  color: ${({ theme }) => theme.colors.text};
  margin: 40px;
`;
