import * as React from "react";
import * as ReactDOM from "react-dom/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { createGlobalStyle, ThemeProvider } from 'styled-components';
import Home from "./pages/Home";
import NotFound from "./pages/NotFound";
import Pmv0340 from "./pages/0430vmp";

// Define your theme
const theme = {
  colors: {
    background: '#16161a',
    card: '#4c4c5a',
    button: '#7f5af0',
    text: '#fffffe',
    paragraph: '#94a1b2',
  },
};

// Global styles using styled-components
const GlobalStyle = createGlobalStyle`
  * {
    user-select: none;
  }
    
  html {
    background-color: ${theme.colors.background};
  }

  body {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    color: ${theme.colors.text}; // Set default text color
  }

  a {
    color: ${theme.colors.button};
    text-decoration: none; // Optional: remove underline from links
  }
`;

// Create your router
const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "/0430vmp",
    element: <Pmv0340 />,
  },
  {
    path: "*",
    element: <NotFound />
  }
]);

// Render your application
ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <RouterProvider router={router} />
    </ThemeProvider>
  </React.StrictMode>
);
