// src/pages/NotFound.js
import React from 'react';
import Layout from '../Layout';

const NotFound = () => {
  return (
    <Layout>
      {/* <h1>404 - Not Found</h1> */}
      {/* <p>What you are looking for does not exist.</p> */}
    </Layout>
  );
};

export default NotFound;
