import { useEffect } from 'react';
//import { useNavigate } from 'react-router-dom';
import React from 'react';
import styled, { keyframes } from 'styled-components';
import Layout from '../Layout';

const Pmv0340 = () => {
    //const navigate = useNavigate();

    useEffect(() => {
    const timer = setTimeout(() => {
        window.location.href = 'https://www.expedia.com/';
    }, 40000); 

    return () => clearTimeout(timer);
    }, []); //[navigate]);
    return (
        <Layout>
            <Container>
            <FadingText style={{ animationDelay: '3s' }}>What are you looking for...?</FadingText>
            <FadingText style={{ animationDelay: '8s' }}>Another wanderer.</FadingText>
            <FadingText style={{ animationDelay: '15s' }}>Another wonderer...</FadingText>
            </Container>
        </Layout>
    );
};

export default Pmv0340;

// Keyframes for the fade-in animation
const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

// Styled components
const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
`;

const Title = styled.h1`
  font-size: 2.5rem;
  color: ${({ theme }) => theme.colors.text};
  animation: ${fadeIn} 1s forwards;
  animation-fill-mode: forwards;
`;

const FadingText = styled.p`
  font-size: 1.5rem;
  color: ${({ theme }) => theme.colors.text};
  opacity: 0;
  padding: 0;
  margin: 8px;
  animation: ${fadeIn} 1s forwards;
  animation-fill-mode: forwards;
`;
